import { Injectable } from '@angular/core'
import { NgbDate, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap'

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {
  fromModel(value: string): NgbDate {
    if (!value) {
      return null
    }
    const split = value.split('-')
    return new NgbDate(
      parseInt(split[0], 10),
      parseInt(split[1], 10),
      parseInt(split[2], 10)
    )
  }

  toModel(date: NgbDate | null): string {
    if (!date) {
      return null
    }
    return `${date.year}-${this.pad(date.month)}-${this.pad(date.day)}`
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`
  }
}
